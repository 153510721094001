@import '../../styles/utils';

.JoinSection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  margin-bottom: 134px;

  @include onTablet {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 50px;
  }

  @include onPhone {
    margin-bottom: 100px;
  }

  &__Title {
    @include mainTitle;
    margin-top: 45px;
    margin-bottom: 28px;

    @include onPhone {
      margin-top: 0;
    }
  }

  &__Description {
    @include description;
    max-width: 620px;
    margin-bottom: 28px;

    &--Bold {
      @include applyFontBold;
      font-weight: 700;
    }
  }

  &__Button {
    background: $c-button-pink;
    padding: 16px 30px;
    border: none;
    outline: none;
    border-radius: 6px;

    cursor: pointer;

    & a {
      @include buttonText;
      text-decoration: none;
    }
  }

  &__Pointing {
    @include onTablet {
      &-Wrapper {
        display: inline-block;
      }
      width: 434px;
    }

    @include onPhone {
      width: 100%;
    }
  }
}