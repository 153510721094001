@import '../../styles/utils';

.About {
  display: flex;
  justify-content: space-between;
  gap: 80px;
  margin-bottom: 128px;

  @include onTablet {
    flex-direction: column;
    margin-bottom: 96px;
    gap: 40px;
  }

  &__Image {
    &--Mobile {
      display: none;
      justify-self: center;
    }

    @include onTablet {
      width: 400px;

      &--Wrapper {
        align-self: flex-start;
      }
    }

    @include onPhone {
      width: 100%;

      &--Wrapper {
        align-self: center;
      }
    }
  }

  &__Title {
    @include sectionTitle;
    margin-bottom: 32px;
  }

  &__Description {
    @include description;

    margin-bottom: 34px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
