@import '../../styles/utils';

.Services {
  --columns-amount: 3;

  @include onTablet {
    --columns-amount: 2;
  }

  @include onPhone {
    --columns-amount: 1;
  }

  @media (min-width: 1200px) {
    --frame-size: 387px;
  }

  &__Top {
    padding: 0 120px;
    text-align: center;
    margin-bottom: 64px;

    @include onTablet {
      text-align: start;
      padding: 0;
    }
  }

  &__Title {
    @include sectionTitle;
    margin-bottom: 28px;
  }

  &__Subtitle {
    @include description;
  }

  &__Frames {
    display: grid;
    justify-content: center;
    gap: 20px;

    grid-template-columns: repeat(var(--columns-amount), 1fr);
  }

  &__Frame {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 48px 16px;

    background-color: $c-white;
    box-shadow: $c-frame-shadow;

    width: 100%;

    text-align: center;

    &--Tablet {
      display: none;

      @include onTablet {
        display: block;
      }

      @include onPhone {
        display: none;
      }
    }

    @include onTablet {
      margin-bottom: 0;
      padding: 36px 16px;
      margin: 0 auto;
    }

    &--Icon {
      margin-bottom: 16px;
    }

    &--Title {
      @include frameTitle;
      margin-bottom: 16px;
    }

    &--Description {
      @include frameDescription;
    }
  }

  &__Group {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &--Reverse {
      @include onTablet {
        &:last-child {
          display: none;
        }
      }

      @include onPhone {
        flex-direction: column-reverse;

        &:last-child {
          display: flex;
        }
      }
    }
  }
}
