@import '../../styles/utils';

.Footer {
  border-top: 1px solid $c-footer-grey;
  padding: 48px 0 12px;
  text-align: center;

  @include onTablet {
    padding: 36px 0;
  }

  &__Top {
    display: flex;
    align-items: center;
    justify-content: center;

    width: min(1200px, 100% - 20px * 2);
    margin: 0 auto;

    @media (max-width: 900px) {
      flex-direction: column;
    }

    border-bottom: 1px solid $c-footer-grey;

    margin-bottom: 12px;
  }

  &__Policies {
    display: block;
    background-color: transparent;
    border: none;
    outline: none;
    margin-bottom: 10px;
    color: #000;
    text-decoration: none;
    @include applyFontBold;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }

  &__Logo {
    margin-bottom: 24px;

    &-Section {
      margin-bottom: 48px;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        @include frameDescription;
        max-width: 370px;

        @media (max-width: 900px) {
          max-width: 100%;
        }
      }
    }
  }

  &__Copyright {
    @include copyright;
  }
}
