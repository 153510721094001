@import '../../styles/utils';

.Contact {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;

  padding-top: 20px;

  @include onTablet {
    display: block;
    margin-bottom: 96px;
  }

  margin-bottom: 128px;

  &__Title {
    @include sectionTitle;
    margin-bottom: 28px;
  }

  &__Description {
    @include description;
    margin-bottom: 64px;

    @include onTablet {
      margin-bottom: 30px;
    }
  }

  &__List {
    list-style: none;
  }

  &__Item {
    display: flex;
    align-items: center;

    margin-bottom: 24px;

    &:nth-child(2) > a {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 6px;
        height: 1px;
        background-color: $c-black;
      }
    }

    &:last-child {
      @include onTablet {
        margin-bottom: 64px;
      }

      @include onPhone {
        margin-bottom: 130px;
      }
    }
  }

  &__Link {
    @include description;
    text-decoration: none;

    margin-left: 15px;
    transform: translateY(2px);
  }

  &__Map {
    align-self: flex-start;
  }

  &__Form {
    justify-self: end;
  }
}