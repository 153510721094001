h1,
body,
p,
button,
h3,
h4,
h2,
h5,
ul,
li {
  margin: 0;
  padding: 0;
}