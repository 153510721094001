@import '../../styles/utils';

.Logo {
  @include onTablet {
    display: block;
    margin: 0 auto;
  }

  &__Wrapper {
    display: flex;
    align-items: center;
    height: 70px;

    margin-bottom: 100px;

    @include onTablet {
      margin-bottom: 48px;
      background-color: $c-white;
    }
  }
}