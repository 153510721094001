@import '../../styles/utils';

.Policies {
  margin-bottom: 128px;
  text-align: center;
  padding: 128px 0 0;

  @include onPhone {
    text-align: start;
    margin-bottom: 100px;
  }

  &__Title {
    @include sectionTitle;
    margin-bottom: 48px;
  }

  &__Frames {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  &__Frame {
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(103, 129, 155, 0.15);
    border-radius: 6px;
    max-width: 785px;
    padding: 48px 32px;

    display: flex;
    gap: 16px;

    @include onPhone {
      flex-direction: column;
      padding: 48px 24px;
    }

    &-Title {
      @include frameTitle;
      margin-bottom: 16px;
    }
  }

  &__Description {
    @include frameDescription;
    text-align: start;
  }
}