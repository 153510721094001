@import '../../styles/utils';

.container {
  position: relative;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 30px;

  background-color: #fff;
  padding: 40px;
  border-radius: 20px;
  max-width: 700px;
  overflow-y: scroll;

  h1 {
    @include secondaryTitle;
      font-size: 32px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #c0c0c0;
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 20px;

    h2 {
      @include secondaryTitle;
      font-size: 20px;
    }

    p {
      @include description;
      font-size: 14px;
    }
  }

  .cross {
    position: fixed;
    top: 40px;
    right: 20px;

    display: block;
    background-image: url(../../images/cross.svg);
    background-position: center;
    background-size: cover;
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;

    cursor: pointer;
  }
}
