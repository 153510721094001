// Media Queries
@mixin onPhone {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin onTablet {
  @media (max-width: 1200px) {
    @content;
  }
}


// Fonts
@mixin applyFont {
  font-family: 'PBSSans', sans-serif;
  font-style: normal;
}

@mixin applyFontBold {
  font-family: 'PBSSans-Bold', sans-serif;
  font-style: normal;
}

@mixin mainTitle {
  @include applyFontBold;
  font-weight: 700;
  font-size: 56px;
  line-height: 117%;

  color: $c-black;
}

@mixin sectionTitle {
  @include applyFontBold;
  font-weight: 700;
  font-size: 48px;
  line-height: 117%;

  color: $c-black;
}

@mixin secondaryTitle {
  @include applyFontBold;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;

  color: $c-black;
}

@mixin description {
  @include applyFont;
  font-weight: 300;
  font-size: 24px;
  line-height: 140%;

  color: $c-black;
}

@mixin frameTitle {
  @include applyFontBold;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;

  color: $c-black;
}

@mixin frameDescription {
  @include applyFont;
  font-weight: 300;
  font-size: 16px;
  line-height: 120%;

  color: $c-black;
}

@mixin copyright {
  @include applyFont;
  font-weight: 300;
  font-size: 14px;
  line-height: 110%;

  color: $c-black;
}

@mixin contacts {
  @include frameTitle;
  @include applyFont;

  font-weight: 300;
}

@mixin buttonText {
  @include applyFontBold;
  font-weight: 900;
  font-size: 18px;
  line-height: 110%;

  color: #FFFFFF;
}

@mixin buttonTextLight {
  @include applyFont;
  font-weight: 300;
  font-size: 18px;
  line-height: 110%;
}