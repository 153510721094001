@import '../../styles/utils';

.Form {
  display: flex;
  flex-direction: column;
  width: 590px;
  padding: 48px 32px;
  border-radius: 6px;
  box-shadow: $c-frame-shadow;
  background-color: $c-white;

  @include onTablet {
    width: 100%;
    padding: 48px 20px;
  }

  &__Title {
    @include secondaryTitle;
    margin-bottom: 32px;
  }

  &__Label {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 12px;

    margin-bottom: 32px;

    @include buttonText;
    color: $c-black;
  }

  &__Input {
    border: 1px solid #CECECE;
    border-radius: 6px;
    outline: none;

    padding: 16px 24px;

    @include buttonTextLight;
    color: $c-black;

    &::placeholder {
      color: black;
    }

    @include onTablet {
      padding: 12px 18px;
    }
  }

  &__Button {
    display: flex;
    justify-content: center;
    align-items: center;

    background: $c-button-pink;
    border: none;
    outline: none;
    border-radius: 6px;
    @include buttonText;
    padding: 16px 0;

    margin-bottom: 16px;

    height: 50px;

    cursor: pointer;
  }

  &__Terms {
    @include frameDescription;
    color: $c-grey;
    text-align: center;
  }

  &__Incorrect {
    position: absolute;
    left: 0;
    bottom: -24px;

    @include copyright;
    color: rgb(227, 54, 54);
  }
}