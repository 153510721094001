@import '../../styles/utils';

.Container {
  padding: 0 120px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  @include onTablet {
    padding: 0 40px;
    max-width: 765px;
  }

  @include onPhone {
    padding: 0 20px;
    max-width: 375px;
  }
}