@import './styles/utils';

.App {
  &__Figure {
    background-repeat: no-repeat;
    background-size: contain;

    &--1 {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      width: 740px;
      height: 700px;

      background-image: url('./images/background01.svg');
      background-position: top right;

      @include onTablet {
        height: 475px;
        width: 704px;
      }

      @include onPhone {
        height: 424px;
        width: 336px;
      }
    }

    &--2 {
      position: absolute;
      top: -4%;
      left: 0;
      z-index: -1;

      background-image: url('./images/background02.svg');
      background-position: top left;

      height: 925px;
      width: 358px;

      @include onTablet {
        top: 0;
        width: 100%;
        height: 587px;
      }

      @include onPhone {
        top: -2%;
        height: 513px;
      }
    }

    &--3 {
      position: absolute;
      bottom: -6%;
      right: 0;
      z-index: -2;

      background-image: url('./images/background03.svg');
      background-position: bottom right;

      width: 762px;
      height: 781px;

      @include onTablet {
        bottom: -3%;
        width: 90%;
        height: 815px;
      }

      @include onPhone {
        background-image: url('./images/background03-mob.svg');
        bottom: -3%;
        height: 815px;
      }
    }
  }
}
